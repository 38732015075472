import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';
import getters from './getters';

// eslint-disable-next-line
import mutations from './mutations';

Vue.use(Vuex);

/**
 * Vuex store object.
 */
export const defaultState = {
  auth: {
    user:          null,
    authenticated: false,
    referrerRoute: null,
  },
  breadcrumbs:           {},
  captureView:           null,
  accountFields:         [],
  accountDocumentFields: [],
  dermaidPresets:        [],
  isDermaidEnabled:      false,
  organizationSettings:  {},
  imageStudio:           {
    images:  [],
    project: {
      pages: {
        images:   [],
        commands: [],
      },
    },
  },
  dermaidStudio: {
    images:  [],
    project: {
      pages: {
        images:   [],
        commands: [],
      },
    },
  },
  videoStudio: {
    view:       null,
    videos:     [],
    sideBySide: [],
    playlists:  {
      playlist1: [],
      playlist2: [],
    },
  },
};

export default new Vuex.Store({
  state:   defaultState,
  plugins: [
    persistedState({
      paths:   [ 'auth', 'imageStudio', 'videoStudio', 'breadcrumbs', 'isDermaidEnabled', 'dermaidStudio' ],
      storage: {
        getItem:    key => sessionStorage.getItem(key),
        setItem:    (key, value) => sessionStorage.setItem(key, value),
        removeItem: key => sessionStorage.removeItem(key),
      },
    }),
  ],
  getters,
  mutations,
});
